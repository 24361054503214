<template>
  <section id="accept-invitation">
    <auth-forms-wrapper
      slot="default"
      title-style="pt-6 pb-4"
      width="448px"
      height="470px"
    >
      <div id="accept-invitation__wrapper" class="px-lg-6 px-2">
        <reset-password-form
          :title="$dictionary.app.acceptInvitation.title"
          :onSubmitHandler="onSubmitHandler"
          :isLoading="isLoading"
          btnText="Create Password"
          :displayMessages="displayMessages"
        />
      </div>
    </auth-forms-wrapper>
  </section>
</template>

<script>
import { AuthFormsWrapper } from "@/components/shared";
import { ResetPasswordForm } from "@/components/forms";

/**
 * Accept inviation view
 * @description This view is just for demonstartion purpose so that the backend can integerate the api's
 * @todo Implemenet the accept inviation service and its response errors with custom messages
 */
export default {
  name: "AcceptInvitation",
  /**
   * ---------------- Components ----------------
   */
  components: {
    AuthFormsWrapper,
    ResetPasswordForm,
  },
  /**
   * ---------------- Data properties ----------------
   */
  data() {
    return {
      isLoading: false,
    };
  },
  /**
   * ---------------- Computed properties ----------------
   */
  computed: {
    /**
     * Error messages
     * @todo Update the display messages with defined in the {acceptInvitation} property in app dictionary
     * @description Fetched from the app dictionary
     */
    displayMessages() {
      const {
        form,
        errorMessages,
        successMessages,
      } = this.$dictionary.app.resetPassword;
      return { ...form, ...errorMessages, ...successMessages };
    },
  },
  /**
   * ---------------- Methods ----------------
   */
  methods: {
    /**
     * Reset password submit handler
     * @todo Add the accept invitation and business logic to accept the invitation of the user
     */
    onSubmitHandler() {},
  },
};
</script>
